import axios from '@axios'

export default {
  namespaced: true,
  state: {
    carAddedAt: 0,
    carUpdateddAt: 0,
  },
  getters: {
    carAddedAt: state => state.carAddedAt,
    carUpdateddAt: state => state.carUpdateddAt,
  },
  mutations: {
    updateCarAddedAt: state => {
      state.carAddedAt = new Date().getTime()
    },
    updateCarUpdatedAt: state => {
      state.carUpdateddAt = new Date().getTime()
    },
  },
  actions: {
    list(ctx, { userId, partner }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/car/list/${userId}?partner=${partner}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    one(ctx, { userId, carId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/car/get/${userId}/${carId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    add(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/car/add/${data.userId}`, data.newCar.value)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    canAdd(ctx, { userId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/can-request-bonus/${userId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPartnerData(ctx, { partnerKey }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/partner/get-redirect/${partnerKey}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPartnerImprint(ctx, { partnerKey }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/partner/get-imprint/${partnerKey}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFaq() {
      return new Promise((resolve, reject) => {
        axios
          .get('/partner/get-faq')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getCarLicenceImages(ctx, { userId, carId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/car/get-licence-images/${userId}/${carId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeStandardPassword(ctx, password) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth-actions/change-standard-password/', password)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // actions end
  },
}
