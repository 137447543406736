<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <!--
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    -->

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!--<dark-Toggler class="d-none d-lg-block" />-->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <locale /> -->
      <b-button
        :to="{name:'faq'}"
        variant="flat-secondary"
        size="sm"
        pill
      >
        FAQ
      </b-button>
      &nbsp;|&nbsp;
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userName }}
            </p>
          </div>

          <b-avatar
            size="50"
            :variant="`light-${partnerData.colorScheme}`"
          />
        </template>
        <b-dropdown-item
          v-if="partnerData.standardLogin"
          v-b-modal.modal-changePassword
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="LockIcon"
            class="mr-50"
          />
          <span>Passwort ändern</span>
        </b-dropdown-item>
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('system.logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <b-modal
      id="modal-changePassword"
      ref="changePasswordModal"
      cancel-variant="outline-secondary"
      :ok-variant="partnerData.colorScheme"
      ok-title="Passwort ändern"
      cancel-title="Schließen"
      centered
      title="Passwort ändern"
      @ok="handleOk"
    >
      <validation-observer ref="changePasswordRules">
        <b-form
          ref="changePasswordForm"
          @submit.stop.prevent="handleSubmit"
        >
          <b-row>
            <!-- password -->
            <b-col cols="12">
              <b-form-group
                label="Passwort"
                label-for="a-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|min:8"
                >
                  <b-form-input
                    id="a-password"
                    v-model="pass"
                    :state="errors.length > 0 ? false:null"
                    type="password"
                    placeholder="Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- confirm password -->
            <b-col cols="12">
              <b-form-group
                label="Passwort wiederholen"
                label-for="ac-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >

                  <b-form-input
                    id="ac-password"
                    v-model="passValue"
                    :state="errors.length > 0 ? false:null"
                    type="password"
                    placeholder="Confirm Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div
                v-if="passChangeError"
              >
                <b-alert
                  :variant="`${partnerData.colorScheme}`"
                  show
                >
                  <div class="alert-body">
                    {{ passChangeError }}
                  </div>
                </b-alert>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar, BButton, BModal, BFormInput, BFormGroup, BRow, BCol, BAlert,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { avatarText } from '@core/utils/filter'
import { ref, onUnmounted } from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

import store from '@/store'
import homeStoreModule from '../../views/homeStoreModule'

export default {
  components: {
    BNavbarNav,
    BButton,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    BModal,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    //  Avatar,

    // Navbar Components
    // . DarkToggler,
    //  Locale,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      avatarText,
    }
  },
  methods: {
    logout() {
      this.$ability.update(initialAbility)
      useJwt.logout()
    },
  },
  setup(props, { emit, refs }) {
    const APP_HOME_STORE_MODULE_NAME = 'app-home'

    // Register module
    if (!store.hasModule(APP_HOME_STORE_MODULE_NAME)) store.registerModule(APP_HOME_STORE_MODULE_NAME, homeStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_HOME_STORE_MODULE_NAME)) store.unregisterModule(APP_HOME_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const passChangeError = ref('')
    const partner = ref(window.$cookies.get('partner'))
    const partnerData = ref(window.$cookies.get('partnerData'))
    const getHrefStyle = () => `color:var(--${partnerData.value.colorScheme})`
    const pass = ref('')

    const authUser = window.$cookies.get('userData')
    const userName = ref(authUser.email)
    if (authUser.fullName !== ' ') {
      userName.value = authUser.fullName
    }

    const handleSubmit = () => {
      passChangeError.value = ''
      store.dispatch(`${APP_HOME_STORE_MODULE_NAME}/changeStandardPassword`, { password: pass.value })
        .then(response => {
          if (response.status === 200) {
            refs.changePasswordModal.hide()
            toast({
              component: ToastificationContent,
              props: {
                title: 'Ihr Passwort wurde erfolgreich geändert!',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
            passChangeError.value = 'Ihr Passwort wurde nicht geändert. Bitte versuchen Sie es erneut!'
          }
        })
    }

    const handleOk = bvModalEvt => {
      bvModalEvt.preventDefault()
      refs.changePasswordRules.validate().then(success => {
        if (success) {
          handleSubmit()
        }
      })
    }

    return {
      userName,

      partner,
      partnerData,
      getHrefStyle,
      handleOk,
      handleSubmit,
      pass,
      passChangeError,
    }
  },
}
</script>
<style>
.dropdown-menu-right {
  width: 200px !important;
}
</style>
