export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'Dashboard',
    action: 'read',
  },
  {
    title: 'Test',
    route: 'generic',
    icon: 'PocketIcon',
    resource: 'Dashboard',
    action: 'read',
  },
]
